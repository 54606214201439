<template>
  <div class="companyPageWrap">
    <m-layout-bar>
      <div class="companyWrap" :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}">
        <div class="content">
          <div class="layout_left">
            <img
              class="profileTitle"
              :src="require('../assets/image/company/profile_title.png')"
              alt
            />
            <div class="detail">
              <p>乐清慢方适文化旅游有限公司成立于2018年5月，注册资金2000万元。公司坐落于世界地质公园雁荡山北麓，铁皮石斛养生小镇核心———大荆镇下山头村。目前开发的主要项目为铁定溜溜项目，属乐商回归招商引资项目，也是慢方适铁皮石斛健康产业链和大荆镇铁皮石斛田园综合体中的核心项目。铁定溜溜占地近2000亩，总投资约11亿，是集石斛文化产业园、农耕文化园、石斛森林公园、石斛深加工产业园、溜溜主题乐园、亲子酒店、山谷酒店、石斛民宿等为一体的综合性园区。首创“铁皮石斛+文化旅游”模式，园内66条特色滑梯由德国BMW团队专利打造。</p>
              <!-- <img class="dec" :src="require('../assets/image/company/dec.png')" alt /> -->
              <div class="dec">
                <img class="dec" :src="require('../assets/image/company/d1.png')" alt />
                <img class="dec" :src="require('../assets/image/company/d2.png')" alt />
                <img class="dec" :src="require('../assets/image/company/d3.png')" alt />
                <img class="dec" :src="require('../assets/image/company/d4.png')" alt />
              </div>
            </div>
          </div>
          <div class="layout_right">
            <img :src="require('../assets/image/company/img.png')" alt />
          </div>

          <!-- 人才招聘 -->
          <!-- <div
            class="recruitmentWrap"
            :style="{background:'url('+rcImgUrl+')',backgroundSize:'100% 100%'}"
          >-->
          <div class="recruitmentWrap">
            <img :src="require('../assets/image/company/recruitment_title.png')" alt />
            <div
              v-for="(item,index) in dataInfo"
              :key="index"
              class="group rec"
              data-active="false"
              :data-index="index"
              :ref="'g'+index"
              @click="openAndClose"
            >
              <div class="profile">
                <img
                  class="arrowBtn open"
                  :src="require('../assets/image/company/arrowOpen.png')"
                  alt
                />
                <img
                  class="arrowBtn close"
                  :src="require('../assets/image/company/arrowClose.png')"
                  alt
                />
                <span class="title">岗位{{index+1}}： {{item.title}}（{{item.num}}）</span>
                <span class="subtitle">{{item.years}} | {{item.addr}} | {{item.education}}</span>
                <span class="dec">职位诱惑：{{item.highlights}}</span>
                <span class="salary">{{item.salary}}</span>
              </div>
              <div class="descWrap">
                <img :src="require('../assets/image/company/icon.png')" alt />
                <div class="layout_right">
                  <div class="group">
                    <span class="title">福利政策</span>
                    <span class="desc">{{item.welfare}}</span>
                  </div>
                  <div class="group">
                    <span class="title">招聘条件</span>
                    <span class="desc">{{item.conditions}}</span>
                  </div>
                  <div class="group">
                    <span class="title">岗位职责</span>
                    <span class="desc" v-html="item.duty"></span>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div
              class="group rec"
              data-active="false"
              data-index="1"
              ref="g1"
              @click="openAndClose"
            >
              <div class="profile">
                <img
                  class="arrowBtn open"
                  :src="require('../assets/image/company/arrowOpen.png')"
                  alt
                />
                <img
                  class="arrowBtn close"
                  :src="require('../assets/image/company/arrowClose.png')"
                  alt
                />
                <span class="title">岗位2： 会计、出纳（1名）</span>
                <span class="subtitle">3-5年工作经验 | 乐清市 | 本科</span>
                <span class="dec">职位诱惑：五险一金、出国游</span>
                <span class="salary">8k-10k / 月</span>
              </div>
              <div class="descWrap">
                <img :src="require('../assets/image/company/icon.png')" alt />
                <div class="layout_right">
                  <div class="group">
                    <span class="title">福利政策</span>
                    <span class="desc">职位诱惑：五险一金、出国旅游</span>
                  </div>
                  <div class="group">
                    <span class="title">招聘条件</span>
                    <span class="desc">职位诱惑：五险一金、出国旅游</span>
                  </div>
                  <div class="group">
                    <span class="title">岗位职责</span>
                    <span class="desc">
                      <P>1、职位诱惑：五险一金、出国旅游</P>
                      <P>2、职位诱惑：五险一金、出国旅游</P>
                    </span>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      bgImgUrl: require("../assets/image/company/bg.png"),
      rcImgUrl: require("../assets/image/company/recru_bg.png"),
      closeFlag: false,
      activeIndex: "",
      dataInfo: new Array()
    };
  },
  mounted() {
    this.getData(config.companyUrl);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    async getData(url) {
      try {
        this.id = this.$route.query.id;
        let res = await axios.get(url, {
          params: {
            id: this.id
          }
        });
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            if (tempData.length > 0) {
              this.dataInfo = tempData;
            }

            // console.log(
            //   "res.data.data:",
            //   this.dataInfo,
            //   this.dataInfo instanceof Array
            // );
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    openAndClose(e) {
      let index = e.currentTarget.getAttributeNode("data-index").value;
      let activeValue = e.currentTarget.getAttributeNode("data-active").value;
      // console.log("第一次取出activeValue:",activeValue);
      activeValue = !JSON.parse(activeValue);
      e.currentTarget.getAttributeNode("data-active").value = activeValue;
      let refName = "g" + index;
      //  console.log("refName:",refName,"activeValue:",activeValue);
      if (activeValue) {
        //增加类名
        this.$refs[refName].classList.add("active");
      } else {
        this.$refs[refName].classList.remove("active");
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.companyWrap {
  border: 1px solid transparent;
  .content {
    width: 1400px;
    min-height: 1979px;
    background: $main-white;
    padding: 66px 38px 66px 74px;
    border: 1px solid transparent;
    margin: 47px auto 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    > .layout_left,
    > .layout_right {
      display: inline-block;
      height: 741px;
      vertical-align: top;
    }
    > .layout_left {
      position: relative;
      width: 661px;
      // background:red;
      > .detail {
        font-size: 18px;
        color: $main-black;
        line-height: 1.8;
        height: 337px;
        overflow: hidden;
        text-indent: 38px;
        > .dec {
          display: flex;
          justify-content: space-between;
          position: absolute;
          width: 653px;
          height: 64px;
          bottom: 100px;
          > img {
            flex: none;
          }
        }
      }
    }
    > .layout_right {
      width: 624px;
      height: 741px;
      > img {
        width: 100%;
        height: 100%;
      }
      //  background:blue;
    }
    // 人才招聘
    .recruitmentWrap {
      text-align: center;
      margin-top: 127px;
      > img {
        margin-bottom: 28px;
      }
      > .group {
        text-align: left;
        margin-bottom: 48px;
        > .profile {
          position: relative;
          background: $main-gray;
          padding: 20px 50px 19px 35px;
          box-sizing: border-box;
          > .title,
          > .subtitle,
          > .dec {
            display: block;
          }
          > .title {
            font-size: 23px;
            font-weight: bold;
            color: $font-greenDark;
          }
          > .subtitle {
            margin-top: 16px;
            margin-bottom: 10px;
            font-size: 13px;
            color: $font-gray;
          }
          > .dec {
            font-size: 14px;
            color: $main-black;
          }
          > .salary {
            position: absolute;
            display: inline-block;
            // width: 50%;
            top: 50%;
            left: 50%;
            transform: translateY(-50%);
            transform: translateX(-50%);
            font-size: 18px;
            color: $main-orange;
            font-weight: bold;
          }
          .arrowBtn {
            position: absolute;
            right: 50px;
            top: 0;
            bottom: 0;
            margin: auto;
            &.open {
              display: none;
            }
          }
        }
        > .descWrap {
          padding: 25px 32px;
          text-align: left;
          background: $main-white;
          border: 1px solid $main-gray;
          border-top: 0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          display: none;
          > .layout_right {
            display: inline-block;
            width: 1091px;
            min-height: 281px;
            vertical-align: top;
            margin-left: 27px;
            > .group {
              > .title,
              .desc {
                display: block;
                color: $font-grayDarkDD;
              }
              .title {
                font-size: 17px;
                font-weight: bold;
              }
              .desc {
                margin-top: 11px;
                margin-bottom: 31px;
                font-size: 13px;
              }
            }
          }
        }
      }
      > .group.active {
        text-align: left;
        margin-bottom: 48px;
        > .profile {
          .arrowBtn.open {
            display: block;
          }
          .arrowBtn.close {
            display: none;
          }
        }
        > .descWrap {
          display: block;
        }
      }
    }
  }
}
</style>
